import React from 'react'
import classNames from 'classnames/bind'

import { Sound } from './types'
import styles from './App.scss'

const css = classNames.bind(styles)

export const SoundCard = ({ sound }: { sound: Sound }) => {
  const [playing, setIsPlaying] = React.useState(false)

  const playSound = () => {
    const audio = new Audio(sound.src)
    setIsPlaying(true)
    audio.onended = () => setIsPlaying(false)
    audio.play()
  }

  return (
    <div
      className={css('card', 'text-center', {
        'bg-warning': playing,
        'bg-dark': !playing
      })}
      onMouseDown={playSound}
    >
      <h1
        className={css('my-auto', {
          'text-dark': playing,
          'text-white': !playing
        })}
      >
        {sound.title}
      </h1>
    </div>
  )
}
